<template>
  <div>
    <v-breadcrumbs
      large
      :items="[
        { text: 'Главная', to: '/' },
        { text: 'Отчеты', disabled: true },
      ]"
      class="pl-0"
    />

    <h1 class="text-h4 mb-6">Список отчетов</h1>
    <ReportsList />
  </div>
</template>

<script>
import ReportsList from "@/components/widgets/reports/ReportsList.vue";
export default {
  components: { ReportsList },

  created() {
    const VIEW_USERS_LIST =
      this.$store.getters["session/isAllow"]("READ_USERS_LIST");
    if (!VIEW_USERS_LIST) this.$router.push({ name: "noRights" });
  },
};
</script>
