var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',[_c('app-table',{attrs:{"headers":_vm.$options.REPORTS_HEADERS,"items":_vm.reports.data,"pagination":_vm.paginator,"items-server-length":_vm.reports.paginatorInfo.total,"loading":_vm.$apollo.queries.reports.loading},on:{"change-page":_vm.changePage,"change-per-page":_vm.changePerPage,"change-visible-headers":_vm.getVisibleHeaders},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.visibleHeaders.includes(_vm.$options.HEADERS_TYPE_FIELDS.id)),expression:"visibleHeaders.includes($options.HEADERS_TYPE_FIELDS.id)"}]},[_c('v-text-field',{attrs:{"label":"ID","dense":"","single-line":"","hide-details":""},model:{value:(_vm.filters.id),callback:function ($$v) {_vm.$set(_vm.filters, "id", $$v)},expression:"filters.id"}})],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.visibleHeaders.includes(_vm.$options.HEADERS_TYPE_FIELDS.title)),expression:"visibleHeaders.includes($options.HEADERS_TYPE_FIELDS.title)"}]},[_c('v-text-field',{attrs:{"label":"Название","dense":"","single-line":"","hide-details":""},model:{value:(_vm.filters.title),callback:function ($$v) {_vm.$set(_vm.filters, "title", $$v)},expression:"filters.title"}})],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.visibleHeaders.includes(_vm.$options.HEADERS_TYPE_FIELDS.comment)
          ),expression:"\n            visibleHeaders.includes($options.HEADERS_TYPE_FIELDS.comment)\n          "}]},[_c('v-text-field',{attrs:{"label":"Описание","dense":"","single-line":"","hide-details":""},model:{value:(_vm.filters.comment),callback:function ($$v) {_vm.$set(_vm.filters, "comment", $$v)},expression:"filters.comment"}})],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.visibleHeaders.includes(_vm.$options.HEADERS_TYPE_FIELDS.status)
          ),expression:"\n            visibleHeaders.includes($options.HEADERS_TYPE_FIELDS.status)\n          "}]},[_c('v-select',{attrs:{"items":_vm.$options.REPORTS_STATUS,"label":"Статус","multiple":"","clearable":"","small-chips":"","deletable-chips":""},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.visibleHeaders.includes(_vm.$options.HEADERS_TYPE_FIELDS.created_at)
          ),expression:"\n            visibleHeaders.includes($options.HEADERS_TYPE_FIELDS.created_at)\n          "}]},[_c('v-text-field',{attrs:{"type":"date","clearable":""},model:{value:(_vm.filters.created_at),callback:function ($$v) {_vm.$set(_vm.filters, "created_at", $$v)},expression:"filters.created_at"}})],1)]},proxy:true},{key:"item._actions",fn:function(ref){
          var item = ref.item;
return [(item.url)?_c('a',{staticClass:"\n            text-decoration-none\n            d-flex\n            justify-center\n            align-items-center\n          ",attrs:{"href":item.url,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }