<template>
  <div>
    <v-card-text>
      <app-table
        :headers="$options.REPORTS_HEADERS"
        :items="reports.data"
        :pagination="paginator"
        :items-server-length="reports.paginatorInfo.total"
        :loading="$apollo.queries.reports.loading"
        @change-page="changePage"
        @change-per-page="changePerPage"
        @change-visible-headers="getVisibleHeaders"
      >
        <template #body.prepend>
          <td v-show="visibleHeaders.includes($options.HEADERS_TYPE_FIELDS.id)">
            <v-text-field
              v-model="filters.id"
              label="ID"
              dense
              single-line
              hide-details
            />
          </td>
          <td
            v-show="visibleHeaders.includes($options.HEADERS_TYPE_FIELDS.title)"
          >
            <v-text-field
              v-model="filters.title"
              label="Название"
              dense
              single-line
              hide-details
            />
          </td>
          <td
            v-show="
              visibleHeaders.includes($options.HEADERS_TYPE_FIELDS.comment)
            "
          >
            <v-text-field
              v-model="filters.comment"
              label="Описание"
              dense
              single-line
              hide-details
            />
          </td>
          <td
            v-show="
              visibleHeaders.includes($options.HEADERS_TYPE_FIELDS.status)
            "
          >
            <v-select
              v-model="filters.status"
              :items="$options.REPORTS_STATUS"
              label="Статус"
              multiple
              clearable
              small-chips
              deletable-chips
            />
          </td>
          <td
            v-show="
              visibleHeaders.includes($options.HEADERS_TYPE_FIELDS.created_at)
            "
          >
            <v-text-field v-model="filters.created_at" type="date" clearable />
          </td>
        </template>
        <template #item._actions="{ item }">
          <a
            v-if="item.url"
            :href="item.url"
            target="_blank"
            class="
              text-decoration-none
              d-flex
              justify-center
              align-items-center
            "
          >
            <v-icon>mdi-download</v-icon>
          </a>
        </template>
      </app-table>
    </v-card-text>
  </div>
</template>

<script>
import AppTable from "@components/base/app-table/app-table";
import { Reports } from "@/graphql/queries/Reports.graphql";
import { InputReportsEnum } from "@generated/graphql";
import {
  convertedReports,
  REPORTS_STATUS,
  HEADERS_TYPE_FIELDS,
  REPORTS_HEADERS,
  REPORT_FILTERS_CONFIG,
} from "@/domain/report";
import { debounce } from "@/utils";
import { getFormattedFilters } from "@/utils/filters";
export default {
  components: { AppTable },

  apollo: {
    reports: {
      query: Reports,
      variables() {
        return {
          first: 5,
          page: 1,
          userId: this.$store.getters["session/me"].id,
          where: [],
          orderBy: [{ column: InputReportsEnum.CreatedAt, order: "DESC" }],
        };
      },
      update(data) {
        return {
          paginatorInfo: data.reports.paginatorInfo,
          data: convertedReports(data.reports.data),
        };
      },
    },
  },

  REPORTS_STATUS,
  HEADERS_TYPE_FIELDS,
  REPORTS_HEADERS,

  data() {
    return {
      paginator: {
        page: 1,
        first: 5,
      },
      reports: {
        paginatorInfo: {
          perPage: 5,
          currentPage: 1,
          total: 0,
          count: 0,
          hasMorePages: true,
        },
        data: [],
      },
      visibleHeaders: [],
      filters: { id: null, title: "", status: [], comment: "", created_at: "" },
    };
  },

  watch: {
    filters: {
      handler() {
        this.fetchFilters();
      },
      deep: true,
    },
  },

  methods: {
    async changePerPage(perPage) {
      this.paginator.first = perPage;
      this.paginator.page = 1;

      await this.$apollo.queries.reports.refetch({
        ...this.fetchVariables(),
        page: this.paginator.page,
        first: this.paginator.first,
      });
    },

    async changePage(page) {
      this.paginator.page = page;

      await this.$apollo.queries.reports.fetchMore({
        variables: {
          page: this.paginator.page,
          first: this.paginator.first,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return {
            reports: {
              __typename: fetchMoreResult?.reports?.__typename,
              paginatorInfo: fetchMoreResult.reports?.paginatorInfo,
              data: [...fetchMoreResult?.reports?.data],
            },
          };
        },
      });
    },

    fetchFilters: debounce(async function () {
      this.paginator.page = 1;

      await this.$apollo.queries.reports.refetch({
        ...this.fetchVariables(),
        page: this.paginator.page,
        first: this.paginator.first,
      });
    }, 500),

    fetchVariables() {
      const formattedFilters = getFormattedFilters(
        this.filters,
        REPORT_FILTERS_CONFIG
      );
      const where = {
        AND: formattedFilters,
      };

      return {
        where,
      };
    },

    getVisibleHeaders(headers) {
      this.visibleHeaders = headers.map((h) => h.value);
    },
  },
};
</script>

<style scoped></style>
